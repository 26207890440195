import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styled from "@emotion/styled";
import { css } from "@emotion/core";

const NavWrap = styled.div`
  ${({ open }) =>
    open &&
    css`
      background-color: #202020;
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 100;
    `};
`;
const StyledNav = styled.div`
  width: 100%;
  max-width: 799px;
  height: auto;
  margin: 0 auto;
  padding: 0 16px;
`;

const NavHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  padding-top: 15px;

  a {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  img {
    position: relative;
    display: block;
    width: 112px;
    height: 39px;
    z-index: 20;
  }
`;

const StyledBurger = styled.button`
  display: block;
  background-image: ${({ open }) =>
    open
      ? "url(/assets/images/nav/burger_close.png)"
      : "url(/assets/images/nav/burger.png)"};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  z-index: 10;

  span {
    overflow: hidden;
    position: absolute;
    left: -9999em;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    background: none;
    text-indent: -9999em;
    }
  }
`;

const NavBody = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 72px);
`;

const StyledGnb = styled.nav`
  display: ${({ open }) => (open ? "block" : "none")};
  padding: 25px 0 15px;
  margin: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 2.5em;
    }
  }

  a {
    width: 328px;
    height: 24px;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
  }
`;
const NavbarGnbItemNow = styled.span`
  color: #75ff6f;
`;
const NavbarCopyright = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  padding: 10px 0 25px;
  font-size: 12px;
  color: #999;
  border-top: 1px solid #333;
`;

const Nav = ({ onClickBurger, open }) => {
  return (
    <NavWrap open={open}>
      <StyledNav>
        <NavHead>
          <Link to={{ pathname: "https://www.doobalhero.kr/" }} target="_blank">
            <img src="/assets/images/common/logo.png" alt="Logo" />
          </Link>
          <StyledBurger open={open} onClick={onClickBurger}>
            <span>메뉴 열기</span>
          </StyledBurger>
        </NavHead>
        <NavBody open={open}>
          <StyledGnb open={open}>
            <ul>
              <li>
                <Link
                  to={{ pathname: "http://dooshop.kr" }}
                  target="_blank"
                >
                  당일배송 브랜드 상품 확인! 
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://www.doobalhero.kr/" }}
                  target="_blank"
                >
                  두발히어로 홈페이지 
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://voc.doobalhero.kr/" }}
                  target="_blank"
                >
                  두발히어로 배송 리뷰 
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://check.doobalhero.kr/" }}
                  target="_blank"
                >
                  배송조회 
                </Link>
              </li>
              <li>
                <NavbarGnbItemNow onClick={onClickBurger}>
                  반품신청 
                </NavbarGnbItemNow>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://www.doobalhero.kr/ " }}
                  target="_blank"
                >
                  배송 계약 문의 
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://rider.doobalhero.kr/" }}
                  target="_blank"
                >
                  배송기사 계약 문의 
                </Link>
              </li>
            </ul>
          </StyledGnb>
          <NavbarCopyright open={open}>
            두발히어로TM
            <br />
            Copyright © 2018 ㈜체인로지스 All rights reserved.
          </NavbarCopyright>
        </NavBody>
      </StyledNav>
    </NavWrap>
  );
};

Nav.propTypes = {
  open: PropTypes.bool,
  onClickBurger: PropTypes.func,
};

export default Nav;
