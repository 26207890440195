import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/core";

const StyledInfo = styled.div`
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding: 35px 16px 30px;

  ${({ open }) =>
    open &&
    css`
       {
        display: none;
      }
    `}

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
  }

  li {
    color: #75ff6f;
    display: block;
    line-height: 1.6em;

    a {
      color: #75ff6f;
      text-decoration: none;
    }
  }

  span {
    color: #999999;
  }

  dl {
    color: #999999;
    margin: 0;
  }

  dt,
  dd {
    margin: 0;
    padding: 0;
  }
`;

const CompanyInfo = ({ open }) => {
  return (
    <StyledInfo open={open}>
      <ul>
        <li>
          대표번호 <a href="tel:1566-0580">1566-0580</a>
        </li>
        <li>
          계약문의{" "}
          <a href="mailto:partner@chainlogis.com">partner@chainlogis.com</a>
        </li>
      </ul>
      <span>---</span>
      <dl className="p-gray">
        <dt>본사</dt>
        <dd>
          서울특별시 용산구 독서당로 39, 1층 두발히어로 물류센터(신성미소시티)
        </dd>
      </dl>
    </StyledInfo>
  );
};

CompanyInfo.propTypes = {
  open: PropTypes.bool,
};

export default CompanyInfo;
