import React, { useContext, useState } from "react";
import { css } from "@emotion/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import styled from "@emotion/styled";

import Ad from "../components/Ad";
import CompanyInfo from "../components/CompanyInfo";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import LoadingComponent from "../components/Loading";

import { ReturnStore } from "../stores/return";
import InputDelImage from "../images/input-del.png";

const BgTriangle = styled.div`
  width: 100%;
  height: 150px;
  background-image: linear-gradient(
    90deg,
    rgba(234, 40, 69, 1) 0%,
    rgba(243, 71, 78, 1) 100%
  );
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    border-top: 30px solid transparent;
    border-right: 100vw solid #202020;
    position: absolute;
    left: 0;
    top: 120px;
  }
`;

const ReturnWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding: 0 16px;

  ${({ open }) =>
    open &&
    css`
       {
        display: none;
      }
    `}

  h2 {
    color: #ffffff;
    font-size: 16px;
    margin: 45px 0 16px;
  }

  p.p-gray {
    color: #999999;
    line-height: 1.6em;

    ::selection {
      background: #75ff6f;
      color: #ffffff;
    }
  }

  hr {
    border: 0.5px solid #333333;
    margin: 25px 0;
  }

  p.p-green {
    color: #75ff6f;
  }
`;

const StyledTitle = styled.div`
  margin-top: 15px;
  color: #ffffff;

  span {
    font-size: 12px;
    line-height: 1.6em;
  }

  h1 {
    font-family: "Black Han Sans", "Spoqa Han Sans", "Spoqa Han Sans JP",
      sans-serif;
    font-size: 32px;
    font-weight: normal;
    margin: 0;
    letter-spacing: 0.05em;
    position: relative;

    &:after {
      content: "1/2";
      font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", sans-serif;
      font-size: 16px;
      position: absolute;
      top: 0;
      margin: 0 10px;
    }
  }
`;
const ReturnForm = styled.form`
  margin: 25px 0;

  input {
    border: none;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    font-size: 16px;
  }

  input:focus {
    outline: none;
  }

  input[type="text"],
  input[type="number"] {
    width: 100%;
    background: #333333;
    border-bottom: 1px solid #999999;
    margin-bottom: 15px;
    color: #ffffff;

    :focus {
      border-bottom: 1px solid #75ff6f;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const SectionApplyButton = styled.button`
  border: none;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  font-size: 16px;
  background: #75ff6f;
  color: #202020;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
`;

const SectionApplyInputGroup = styled.div`
  position: relative;
`;

const SectionApplyInputIcon = styled.div`
  display: none;
  position: absolute;
  right: 9px;
  top: 9px;
  width: 32px;
  height: 32px;

  ${({ filled }) =>
    filled &&
    css`
       {
        display: block;
        background-image: url(${InputDelImage});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    `}
`;

const Index = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickBurger = () => {
    setOpen(!open);
  };

  const [state, setState] = useState({
    bookId: "",
    mobile: "",
    context: "",
  });

  const handleChange = (e) => {
    let _state = { ...state };
    _state[e.target.name] = e.target.value;

    setState(_state);
  };

  const isNumberKey = (e) => {
    let charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  };

  const reset = (name) => {
    let _state = { ...state };
    _state[name] = "";

    setState(_state);
  };

  const { fetchReturnData } = useContext(ReturnStore);

  const onSubmit = ({ bookId, mobile }) => {
    setLoading(true);

    fetchReturnData({ bookId, mobile })
      .then(() => {
        history.push("/Inquiry");
        window.scrollTo(0, 0);

        setLoading(false);
      })
      .catch((e) => {
        alert(`조회를 실패했습니다.\n${e?.message}`);
        setLoading(false);
      });
  };

  const { register, handleSubmit } = useForm();

  let history = useHistory();

  return (
    <div className="App">
      {loading && <LoadingComponent />}
      <BgTriangle />
      <Nav open={open} onClickBurger={handleClickBurger} />
      <ReturnWrap open={open}>
        <StyledTitle>
          <span>4시간 오늘 도착 배송혁명</span>
          <h1>반품신청</h1>
        </StyledTitle>

        <h2>두발히어로 반품 신청 전 꼭 확인해주세요.</h2>
        <p className="p-gray">
          1. 반품신청 가능한 고객사 물품이 아닌 경우 반품 신청이 안 될 수
          있습니다. 주문하신 곳으로 문의 부탁드립니다. <br />
          2. 방문일정 : 접수일자 + 1일 이내(영업일 기준) <br />
          3. 일요일‚ 공휴일은 방문하지 않습니다. <br />
          4. 취급주의상품은 보호재포장을 필요로 하며 박스포장이 완료되지 않은
          경우 수거가 불가능합니다. <br />
          5. 박스크기가 가로+세로+높이 합이 80cm이 넘는 경우수거가 불가능합니다.{" "}
          <br />
          6. 박스에 반품지명, 반품지주소, 반품지 연락처가 기재되어 있어야
          합니다. <br />
          7. 상품인수를 위한 방문시 전화통화가 되지 않거나 물품 보관위치 확인이
          되지 않는 경우 수거 방문하지 않으니 전화를 꼭 받아 주시거나 보관위치를
          정확하게 기재해주십시오. <br />
          8. 오전/오후 및 특정시간을 지정하여 방문 요청하실 경우 반영되지
          않습니다. (예: 오전에 꼭 와주세요. 오후에만 사람이 있습니다. 2∼3시경
          방문요망 등)
        </p>

        <hr />

        <p className="p-green">
          반품신청할 접수번호와 연락처를 정확히 입력해주십시오. <br />
          *접수번호는 배송 진행 중 카카오알림톡/문자메시지로 전송되었습니다.{" "}
          <br />
          *문의: return@chainlogis.com
        </p>

        <ReturnForm onSubmit={handleSubmit(onSubmit)}>
          <SectionApplyInputGroup>
            <input
              type="text"
              placeholder="접수번호를 입력해주세요"
              onChange={handleChange}
              value={state.bookId}
              name="bookId"
              ref={register({ required: true })}
            />
            <SectionApplyInputIcon
              filled={state.bookId}
              onClick={() => reset("bookId")}
            />
          </SectionApplyInputGroup>
          <SectionApplyInputGroup>
            <input
              type="number"
              placeholder="연락처를 숫자만 입력해주세요"
              onChange={handleChange}
              value={state.mobile}
              onKeyPress={isNumberKey}
              name="mobile"
              ref={register({ required: true })}
            />
            <SectionApplyInputIcon
              filled={state.mobile}
              onClick={() => reset("mobile")}
            />
          </SectionApplyInputGroup>
          <SectionApplyButton type="submit">조회 </SectionApplyButton>
        </ReturnForm>
        <Ad open={open} />
      </ReturnWrap>
      <CompanyInfo open={open} />
      <Footer open={open} />
    </div>
  );
};

export default Index;
